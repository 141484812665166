<template>
  <div class="mt-5">
    <div class="row">
      <side-menu></side-menu>
      <div class="col-xl-9 col-lg-8">
        <div class="m-portlet">
          <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
              <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">Convite</h3>
              </div>
            </div>
          </div>
          <div>
            <div class="m-portlet__body">
              <div class="row">
                <div class="col-6">
                  <div class="form-group m-form__group">
                    <label for="company">Empresa</label>
                    <v-select
                      :options="companies"
                      placeholder="Selecione uma Empresa"
                      v-model="invite.company_id"
                    >
                      <span slot="no-options">Nenhum resultado.</span>
                    </v-select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group m-form__group">
                    <label for="company">Grupo de Permissões</label>
                    <v-select
                      :options="roles"
                      placeholder="Selecione o grupo de permissões"
                      v-model="invite.role_id"
                    >
                      <span slot="no-options">Nenhum resultado.</span>
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group m-form__group">
                    <label for="example_input_full_name">Email</label>
                    <input type="email" @keyup="() => {invite.email = invite.email.toLowerCase()}" v-model="invite.email" class="form-control m-input">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group m-form__group">
                    <label for="company">Perfil</label>
                    <v-select
                      :options="profiles"
                      placeholder="Selecione o Perfil"
                      v-model="invite.profile_id"
                    >
                      <span slot="no-options">Nenhum resultado.</span>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="m-portlet__foot">
              <div class="row align-items-right text-right">
                <div class="col-lg-12">
                  <button @click="save" type="button" class="btn btn-brand">Enviar convite</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import SideMenu from "../components/SideMenu";
import toastr from "toastr";
import VSelect from "vue-select";

export default {
	name: "invite",
	components: {
		SideMenu,
		VSelect
	},
	data() {
		return {
      companies: [],
      roles: [],
      profiles: [],
			invite: {
				company_id: null,
        email: "",
        role_id: null,
        profile_id: null,
			}
		};
	},
	created() {
		this.getCompanies();
	},
	methods: {
		save() {
			let invite = {
				company_id: this.invite.company_id.value,
        email: this.invite.email,
        role_id: this.invite.role_id.value,
        profile_id: this.invite.profile_id.value
			};
			axios
				.post("/user/invite", invite)
				.then(() => {
					this.invite = {
						company_id: null,
            email: "",
            role_id: null,
            profile_id: null,
					};
					swal("sucesso", "Convite enviado com Sucesso!", "success");
				})
				.catch(error => {
          console.log(error);

          swal("Erro!", "O usuário já está cadastrado no sistema", "error");

        });
		},
		getCompanies() {
			axios
				.get("/get-invitation-data")
				.then(response => {
					response.data.partners.forEach(company => {
						let obj = {
							label: company.name,
							value: company.id
						};
						this.companies.push(obj);
          });
          response.data.roles.forEach(role => {
						let obj = {
							label: role.name,
							value: role.id
						};
						this.roles.push(obj);
          });
          response.data.profiles.forEach(profile => {
						let obj = {
							label: profile.name,
							value: profile.id
						};
						this.profiles.push(obj);
					});
				})
				.catch(error => console.log(error));
		}
	}
};
</script>

<style>
</style>
